@import '~/bb/ui/Styles/variables';
@import '~/bb/ui/Styles/functions';

.modal {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-bottom: 0;

    > * {
        display: flex;
        flex: 1;
        flex-direction: column;

        > * {
            flex: 1;
        }
    }

    @media (min-width: $screen-size-lg) {
        min-width: 600px;
        max-width: max-content !important; // gets overriden by actionModals own max-width
    }
}

.modalContent {
    flex: 1;
    max-width: 600px;
}

.cancelButton {
    width: fit-content;
    margin: 0 auto;
    overflow: hidden;
}

.strikethrough {
    padding-left: spacing(1);
    text-decoration: line-through;
}

.image {
    width: 100%;
    height: 200px;
    object-fit: contain;
}

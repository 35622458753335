@import '~/bb/ui/Styles/functions';
@import '~/bb/ui/Styles/variables';

$padding: spacing(4);

.filterName {
    & > * + * {
        border-top: 1px solid var(--color-gray-01);
    }
}

.sidePadding {
    padding-right: $padding;
    padding-left: $padding;
}

.inputComponent {
    padding-top: $padding;
    padding-bottom: $padding;

    & * {
        font-size: var(--font-base-size);
    }
}

.checkbox {
    & > *:first-child {
        flex-direction: row-reverse;
        justify-content: space-between;
        width: 100%;
    }
}

.radio.radio {
    flex-direction: row-reverse;
    justify-content: space-between;
}

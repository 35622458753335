.illustrationBackground {
    background-image: url('https://images.ctfassets.net/4s7izhcdroyy/3M6qOFVkrIbLFxjik1VfVo/30a103a994931d80ebed29dfbac37b20/illustration_red.webp?h=800&fm=jpg');
    background-position: 30%;
    background-size: 140%;
}

.hero {
    max-height: 500px;
    object-fit: cover;
}

.logoHero {
    max-height: 300px;
    object-fit: contain;
}

@import '~/bb/ui/Styles/variables';

.big {
    font-size: 3rem;
    font-weight: bold;
}

.gridContainer {
    display: grid;
    grid-template-columns: 1fr;
    gap: var(--distance-large);
    width: 100%;

    @media screen and (min-width: $screen-size-md) {
        grid-template-columns: repeat(3, 1fr);
    }
}

@import '~/bb/ui/Styles/variables';
@import '~/bb/ui/Styles/functions';

.root {
    padding: spacing(4, 0);

    @media (width >= $screen-size-sm) {
        position: static;
        padding: 0;
        background-color: inherit;
    }

    & button:not(:last-child) {
        background-color: var(--color-primary-white);
    }
}

@import '~/bb/ui/Styles/variables';

.root {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
}

.hero {
    max-width: 450px;
    margin-right: auto;
    margin-left: auto;
}

.qr {
    display: flex;
    flex-direction: column;

    // Size of (padding * 2) + width of QR
    max-width: ($base-spacing-unit * 2) + 120px;
    background-color: var(--color-primary-black);
    border: 4px solid var(--color-primary-black);
}

.qrText {
    padding: $base-spacing-unit 0;
    overflow: hidden;
    text-align: center;
    text-overflow: ellipsis;
}

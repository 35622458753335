@import '~/bb/ui/Styles/variables';

.text {
    h2,
    h3,
    h4,
    h5 {
        font-family: var(--font-secondary);
        font-weight: 400;
    }

    h1 {
        font-family: var(--font-primary);
        font-size: 2.55rem;
        font-weight: bold;
        line-height: 115%;
        text-align: center;

        @media (min-width: $screen-size-md) {
            font-size: 4.5rem;
            line-height: 100%;
        }
    }

    p,
    ul {
        margin-bottom: var(--distance-extra-small);
    }

    a {
        font-weight: normal;
    }

    img {
        max-width: 100%;

        @media (min-width: $screen-size-sm) {
            max-width: 400px;
        }
    }
}

.leftColText {
    h1 {
        font-family: var(--font-secondary);
        font-size: 2.55rem;
        font-weight: 400;
        line-height: 115%;
        text-align: left;

        @media (min-width: $screen-size-md) {
            font-size: 2.55rem;
            line-height: 115%;
        }
    }
}

.base {
    position: sticky;
    bottom: 0;
    z-index: 1;
    display: flex;
    justify-content: center;
}

.actions {
    width: 100%;
    max-width: 600px;
}

@import '~/bb/ui/Styles/variables';

$size: 20px;

.root {
    &:focus-within {
        outline: 2px solid var(--color-focus);
        outline-offset: 2px;
    }
}

.container {
    *:checked ~ & {
        .input {
            background-color: var(--color-primary-purple);
            background-image: url('/images/svg/check-black.svg');
            background-repeat: no-repeat;
            background-position: center;
            background-size: 16px;
            border-color: var(--color-primary-purple);
        }
    }

    *:hover ~ & {
        .input {
            background-color: var(--color-primary-purple);
            border-color: var(--color-primary-purple);
        }
    }
}

.input {
    display: inline-block;
    flex-shrink: 0;
    width: $size;
    height: $size;
    background-color: var(--color-primary-white);
    border: 1px solid var(--color-primary-black);
    border-radius: 50%;
    transition:
        background-color 0.2s,
        background-image 0.2s;
}

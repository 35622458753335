@import '~/bb/ui/Styles/functions';

.root {
    & img {
        width: auto;
        height: 20px;
    }
}

.listItem {
    display: flex;
    align-items: center;
    height: 20px;
}

// Override the higher specificity of .root styles.
.visa.visa {
    height: 16px;
}

@import '~/bb/ui/Styles/functions';
@import '~/bb/ui/Styles/variables';

.whiteCardInner {
    padding: spacing(6, 4);
    word-wrap: break-word;

    @media (width >= $screen-size-md) {
        padding: spacing(8, 6);
    }
}

.card {
    padding: spacing(4);
}

// Override high specificity
.image.image {
    display: flex;
    justify-content: center;
    width: auto;
    max-width: 100%;
    height: 200px;
}

.reactivate {
    display: flex;
    gap: spacing(3);
    align-items: center;
    justify-content: center;
    text-decoration: none;
}

.reactivateBox {
    $size: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: $size;
    height: $size;
    padding: 2px;
}

@import '~/bb/ui/Styles/variables';

.grow {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
}

.alignBottom {
    margin-top: auto;
}

.desktopCentered {
    @media (min-width: $screen-size-md) {
        align-items: center;
        justify-content: center;
    }
}

.stepBox {
    @media (max-width: $screen-size-md) {
        background-color: var(--color-gray-00);
    }
}

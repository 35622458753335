@import '~/bb/ui/Styles/variables';

.column {
    --span: 12;

    @media (width >= 768px) {
        --span: 6;
    }
}

.base {
    border-bottom: 1px solid;
}

.right {
    @media (width >= 768px) {
        padding-left: var(--distance-extra-small);
    }
}

@import '~/bb/ui/Styles/functions';

.bookPromoImage {
    max-width: var(--max-size);
    max-height: var(--max-size);
    object-fit: contain;
    box-shadow: 0 6px 12px rgb(0 0 0 / 25%);

    --max-size: 370px;

    @media (max-width: $screen-size-sm) {
        --max-size: 270px;
    }
}

.image {
    height: 100%;
    object-fit: cover;
}

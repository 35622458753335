@import '~/bb/ui/Styles/variables';

.flex {
    flex-direction: column;

    @media screen and (width >= 992px) {
        flex-direction: row;
    }
}

.background {
    padding: 4px 8px;
    background-color: var(--color-tertiary-purple);
}

.strikethrough {
    padding-left: 5px;
    text-decoration: line-through;
}

@import '~/bb/ui/Styles/variables';

$custom-padding: 4rem;

.border {
    position: relative;
    display: grid;
    place-items: center;
    padding-right: $custom-padding;
    padding-left: $custom-padding;
    margin: var(--distance-extra-small) 0 var(--distance-extra-small) 0;
    border: 2px dotted var(--color-gray-06);
}

.noCopyButton {
    grid-template-columns: auto 1fr auto;
}

.code {
    margin: var(--distance-extra-small);
}

.copy {
    position: absolute;
    top: 0.4rem;
    right: 1rem;

    &:focus {
        svg {
            fill: var(--color-primary-black);
        }
    }
}

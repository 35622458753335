@import '~/bb/ui/Styles/variables';

.box {
    top: 50%;
    left: 50%;
    width: 100%;
    max-width: 600px;
    opacity: 0;
    transition-timing-function: ease-out;
    transition-duration: var(--modal-animation-duration);
    transition-property: opacity, scale;
    scale: 0.5;
    translate: -50% -50%;

    &[data-isopen='true'] {
        opacity: 1;
        scale: 1;
    }

    @media (max-width: $screen-size-sm) {
        top: auto;
        bottom: 0;
        left: 0;
        width: 100vw;
        max-width: none;
        opacity: 1;
        transition-property: translate;
        scale: 1;
        translate: 0 100%;

        &[data-isopen='true'] {
            translate: 0;
        }
    }

    & > *:first-child {
        display: flex;
        flex-direction: column;
    }
}

.variant-windowed {
    max-height: 90vh;
}

.variant-full-screen {
    max-height: 100vh;

    @media (max-width: $screen-size-sm) {
        height: 100vh;

        /* Fix for content being hidden behind status bar on iOS Safari, dynamic viewport height */
        height: 100dvh;
    }
}

.grow {
    flex: 1;
}

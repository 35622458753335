@import '~/bb/ui/Styles/variables';
@import '~/bb/ui/Styles/functions';

.root {
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 1000;
    display: flex;
    justify-content: center;
    width: 400px;
    height: min-content;
    min-height: 80vh;
    padding: 20px;
    transform: translate(-50%, -50%);

    @media screen and (max-width: $screen-size-sm) {
        width: 100%;
        height: 100%;
    }
}

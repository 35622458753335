.unselected {
    opacity: 0.3;
    transition: opacity 0.4s;

    &:hover {
        opacity: 0.5;
    }
}

.noPadding {
    padding: 0;
}

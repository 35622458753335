$size: 16px;

.root {
    &:focus-within {
        outline: 2px solid var(--color-focus);
        outline-offset: 2px;
    }
}

.upperInnerContainer {
    display: flex;
    align-items: center;
    height: 60px;
    padding-left: 16px;
}

.bottomInnerContainer {
    padding: 8px 16px 16px;
}

.bottomInnerContainer button {
    border-radius: 4px;
}

.giftcardText {
    font-weight: 500;
}

.container {
    min-height: 60px;
    border: 1px solid;
    border-color: rgb(219 222 226);
    border-radius: 8px;
}

.containerChecked {
    background: rgb(247 247 248);
}

.iconWrapper {
    width: 40px;
    height: 26px;
}

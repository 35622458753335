@import '~/bb/ui/Styles/functions';
@import '~/bb/ui/Styles/variables';

.root {
    padding: spacing(4);
    outline: 1px solid transparent;
    transition: outline 0.2s;
}

.focused {
    outline: 1px solid var(--color-primary-purple);
    outline-offset: -1px;
}

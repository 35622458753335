@import '~/bb/ui/Styles/variables';

// Ensure specificity
.flexContainer.flexContainer {
    flex-direction: column;

    @media screen and (width >= 1085px) {
        flex-direction: row;
    }
}

.container {
    width: 300px;
}

.bullet {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2rem;
    height: 2rem;
    padding: var(--distance-extra-small);
    background: var(--color-secondary-green);
    border-radius: 50%;

    > span {
        line-height: 2rem;
    }
}

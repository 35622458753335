@import '~/bb/ui/Styles/variables';

.image {
    height: 100%;
}

.modal {
    display: flex;
    flex-direction: column;
    min-height: 65vh;

    @media (max-width: $screen-size-sm) {
        min-height: 80vh;
    }
}

.container {
    flex: 1;
}

.bannerImage {
    width: 90px;
    height: 90px;
}

@import '~/bb/ui/Styles/variables';

.codeContent {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.hideOverflow {
    overflow: hidden;
}

.column {
    --span: 12;

    @media (width >= 768px) {
        --span: 6;
    }
}

.right {
    @media (width >= 768px) {
        padding-left: var(--distance-extra-small);
    }
}

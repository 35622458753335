@import '~/bb/ui/Styles/variables';
@import '~/bb/ui/Styles/functions';

.qr {
    display: flex;
    flex-direction: column;
    max-width: calc((var(--base-spacing-unit) * 2) + 120px);
}

.qrText {
    padding: var(--base-spacing-unit) 0;
    overflow: hidden;
    text-align: center;
    text-overflow: ellipsis;
}

@import '~/bb/ui/Styles/functions';

$spacing: spacing(4);

.buttonContainer {
    position: sticky;
    bottom: 0;
    padding: $spacing;
    margin-top: auto;
    background-color: var(--color-primary-white);
}

.filter {
    display: flex;
    flex: 1;
    flex-direction: column;

    & > div {
        flex: 1;
    }
}

.content {
    display: flex;
    flex-direction: column;
}

@import '~/bb/ui/Styles/variables';
@import '~/bb/ui/Styles/functions';

.root {
    position: relative;
    flex: 1;
    width: 100%;
    max-width: 1000px;
    height: 100%;
    word-wrap: break-word;

    @media (width >= $screen-size-md) {
        flex: 0;
        margin: spacing(6);
    }
}

.bar {
    padding: spacing(4);
}

.inner {
    padding: spacing(5, 4);
}

@import '~/bb/ui/Styles/variables';
@import '~/bb/ui/Styles/functions';

.box {
    top: 50%;
    left: 50%;
    max-width: var(--wrong-market-modal-max-width);
    max-height: calc(100vw - #{spacing(4)});
    translate: -50% -50%;

    @media screen and (max-width: $screen-size-sm) {
        width: calc(100vw - #{spacing(4)});
        min-width: calc(100vw - #{spacing(4)});
        max-width: none;
    }
}
